import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { useEffect, useMemo, useState } from "react";
import { FaPenNib } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { notify } from "../../gateway/Functions";
import {
  deleteSurvey,
  getSurveyList,
} from "../../gateway/gRPC/Utils/SurveyMethods";
import { formatTimeStamp } from "../../gateway/gRPC/Utils/utils";
import { Survey } from "../../gateway/gRPC/pb/surveys_pb";
import strings from "../../localization/main";
import { openModalAlert } from "../../redux/ModalAlertObj";
import MyTable from "../MyTable";
import CustomDeleteButton from "../buttons/CustomDeleteButton";
import CustomViewButton from "../buttons/CustomViewButton";
import IconButton from "../buttons/IconButton";
import PageContainer from "../containers/PageContainer";
import TableActionContainer from "../crm/tables/TableActionContainer";
import CustomPageHeader from "../headers/CustomPageHeader";
import AddSurvey from "./questionnaire/AddSurvey";
import { User } from "../../gateway/gRPC/pb/user_pb";

export default function Surveys() {
  const [data, setData] = useState<Survey.AsObject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const user: User.AsObject = useSelector((state: any) => state.Profile);
  const loggedIn = useSelector((state: any) => state.LoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getSurveyList(`${user.organisationId}`)
      .then((res) => {
        setData(res.surveysList);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDelete = (id: string) => {
    dispatch(
      openModalAlert(
        "Delete Survey",
        "Are you sure you want to delete this survey?. This will delete the survey and all responses to it. This action cannot be undone!",
        "danger",
        true,
        true,
        () => deleteCallback(id),
      ),
    );
  };
  const deleteCallback = (id: string) => {
    setLoading(true);
    deleteSurvey(id)
      .then(() => {
        notify(
          "success",
          "bottomLeft",
          "Survey deleted successfully",
          "This survey and all its responses has been deleted successfully",
        );
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        title: "Title",
        dataIndex: "title",
        key: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Serial No",
        dataIndex: "serialNumber",
        key: "serial_number",
      },
      {
        title: "No of Responses",
        dataIndex: "responseCount",
        key: "responses",
      },
      {
        title: "Max Responses",
        dataIndex: "maxResponses",
        key: "status",
      },
      {
        title: "Expires At",
        dataIndex: "expires",
        key: "expires",
        render: (date: Timestamp.AsObject) =>
          date?.seconds > 10 && formatTimeStamp(date),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date: Timestamp.AsObject) =>
          date?.seconds > 10 && formatTimeStamp(date),
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "action",
        width: "10%",
        align: "center",
        render: (id: string) => (
          <TableActionContainer>
            <IconButton
              title={"AddResponse"}
              icon={<FaPenNib />}
              onClick={() => navigate(`${id}/new_response`)}
            />
            <CustomDeleteButton onClick={() => handleDelete(id)} />
            <CustomViewButton onClick={() => navigate(`${id}`)} />
          </TableActionContainer>
        ),
      },
    ],
    [],
  );

  return (
    <PageContainer p={loggedIn ? ".5rem" : "3rem 10rem"}>
      <CustomPageHeader
        title={strings.surveys}
        description={strings.create_and_manage_surveys}
      />
      <MyTable
        columns={columns}
        data={[...data]}
        loading={loading}
        action={
          <TableActionContainer>
            <AddSurvey />
          </TableActionContainer>
        }
      />
    </PageContainer>
  );
}
