import { RpcError } from "grpc-web";
import {
	appService,
	authService,
	branchService,
	contactGroupService,
	contactService,
	departmentService,
	labelService,
	orgService,
	permissionService,
	roleService,
	tagService,
	userService,
} from "../globalClient/GlobalClients";
import {
	App,
	CreateAppRequest,
	CreateAppResponse,
	CreateAppWithTemplateRequest,
	CreateAppWithTemplateResponse,
	DeleteAppResponse,
	ReadAppResponse,
	ReadAppsResponse,
	ReadAppTemplatesRequest,
	ReadAppTemplatesResponse,
	UpdateAppRequest,
	UpdateAppResponse,
} from "../pb/apps_pb";
import {
	ChangePasswordRequest,
	ChangePasswordResponse,
	ForgotPasswordRequest,
	ForgotPasswordResponse,
	LoginRequest,
	LoginResponse,
	LogoutRequest,
	LogoutResponse,
	RefreshTokenRequest,
	RefreshTokenResponse,
} from "../pb/auth_pb";
import {
	ReadBranchRequest,
	ReadBranchResponse,
	ReadBranchesRequest,
	ReadBranchesResponse,
} from "../pb/branch_pb";
import {
	CreateGroupRequest,
	CreateGroupResponse,
	DeleteGroupRequest,
	DeleteGroupResponse,
	ReadGroupContactsRequest,
	ReadGroupContactsResponse,
	ReadGroupRequest,
	ReadGroupResponse,
	ReadGroupsRequest,
	ReadGroupsResponse,
	UpdateGroupRequest,
	UpdateGroupResponse,
} from "../pb/contact_groups_pb";
import {
	BulkContactsRequest,
	BulkContactsResponse,
	Contact,
	CreateContactRequest,
	CreateContactResponse,
	DeleteContactRequest,
	DeleteContactResponse,
	FindContactByEmailOrPhoneRequest,
	FindContactByEmailOrPhoneResponse,
	ReadAccountContactsRequest,
	ReadAccountContactsResponse,
	ReadContactChildrenRequest,
	ReadContactChildrenResponse,
	ReadContactRequest,
	ReadContactResponse,
	ReadContactsRequest,
	ReadContactsResponse,
	ReadCustomFieldDefinitionsRequest,
	ReadCustomFieldDefinitionsResponse,
	SearchContactsRequest,
	SearchContactsResponse,
	UpdateContactRequest,
	UploadDocumentRequest,
	UploadDocumentResponse,
} from "../pb/contact_pb";
import {
	ReadDepartmentRequest,
	ReadDepartmentResponse,
	ReadDepartmentsRequest,
	ReadDepartmentsResponse,
} from "../pb/department_pb";
import { Group } from "../pb/group_pb";
import {
	CreateLabelRequest,
	CreateLabelResponse,
	DeleteLabelRequest,
	DeleteLabelResponse,
	Label,
	ReadLabelRequest,
	ReadLabelResponse,
	ReadLabelsRequest,
	ReadLabelsResponse,
	UpdateLabelRequest,
	UpdateLabelResponse,
} from "../pb/label_pb";
import {
	CreateOrganisationRequest,
	CreateOrganisationResponse,
	Organisation,
	ReadOrganisationRequest,
	ReadOrganisationResponse,
	UpdateOrganisationRequest,
	UpdateOrganisationResponse,
} from "../pb/organisations_pb";
import {
	CreateRoleRequest,
	CreateRoleResponse,
	DeleteRoleRequest,
	DeleteRoleResponse,
	PermissionToRoleRequest,
	PermissionToRoleResponse,
	ReadPermissionsRequest,
	ReadPermissionsResponse,
	ReadRoleRequest,
	ReadRoleResponse,
	ReadRolesRequest,
	ReadRolesResponse,
	Role,
	UpdateRoleRequest,
	UpdateRoleResponse,
} from "../pb/rbac_pb";
import {
	CreateTagRequest,
	CreateTagResponse,
	DeleteTagRequest,
	DeleteTagResponse,
	ReadTagRequest,
	ReadTagResponse,
	ReadTagsRequest,
	ReadTagsResponse,
	Tag,
	UpdateTagRequest,
	UpdateTagResponse,
} from "../pb/tag_pb";
import {
	CreateUserRequest,
	CreateUserResponse,
	DeactivateUserRequest,
	DeactivateUserResponse,
	DeleteUserRequest,
	DeleteUserResponse,
	ReadUserRequest,
	ReadUserResponse,
	ReadUsersRequest,
	ReadUsersResponse,
	RoleToUserRequest,
	RoleToUserResponse,
	SearchUsersRequest,
	SearchUsersResponse,
	UpdateUserRequest,
	UpdateUserResponse,
	User,
} from "../pb/user_pb";
import {
	formatOrgJsonToOrgClass,
	newAppClass,
	newContactClass,
	newContactGroupClass,
	newLabelClass,
	newRoleClass,
	newTagClass,
	newUploadDocumentClass,
	newUserClass,
	updateUserClass,
} from "./orgutils";

const tokenObject = () => {
	const accessToken = localStorage.getItem("accessToken");
	return {
		authorization: accessToken || "",
	};
};

export const gRPCRefreshToken = (
	refreshToken: string
): Promise<RefreshTokenResponse.AsObject> => {
	let request = new RefreshTokenRequest();
	request.setRefreshToken(refreshToken);
	return new Promise<RefreshTokenResponse.AsObject>((resolve, reject) => {
		authService.refreshToken(
			request,
			tokenObject(),
			(err: RpcError, response: RefreshTokenResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCCreateOrganisation = (
	data: Organisation.AsObject
): Promise<CreateOrganisationResponse.AsObject> => {
	let request = new CreateOrganisationRequest();
	request.setOrganisation(formatOrgJsonToOrgClass(data));

	return new Promise<CreateOrganisationResponse.AsObject>((resolve, reject) => {
		orgService.createOrganisation(
			request,
			undefined,
			(err: RpcError, response: CreateOrganisationResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const updateOrganisation = (
	data: Organisation.AsObject
): Promise<UpdateOrganisationResponse.AsObject> => {
	let request = new UpdateOrganisationRequest();
	request.setOrganisation(formatOrgJsonToOrgClass(data));

	return new Promise<UpdateOrganisationResponse.AsObject>((resolve, reject) => {
		orgService.updateOrganisation(
			request,
			undefined,
			(err: RpcError, response: UpdateOrganisationResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const getOrganisation = (
	id: string
): Promise<ReadOrganisationResponse.AsObject> => {
	let request = new ReadOrganisationRequest();
	request.setOrganisationId(id);

	return new Promise<ReadOrganisationResponse.AsObject>((resolve, reject) => {
		orgService.readOrganisation(
			request,
			{
				Authorization: localStorage.getItem("accessToken") ?? "",
			},
			(err: RpcError, response: ReadOrganisationResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCCreateUser = (
	user: User.AsObject
): Promise<CreateUserResponse.AsObject> => {
	let request = new CreateUserRequest();
	request.setUser(newUserClass(user));

	return new Promise<CreateUserResponse.AsObject>((resolve, reject) => {
		userService.createUser(
			request,
			tokenObject(),
			(err: RpcError, response: CreateUserResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCGetMe = (): Promise<ReadUserResponse.AsObject | null> => {
	const request = new ReadUserRequest();

	return new Promise<ReadUserResponse.AsObject | null>((resolve, reject) => {
		userService.readMe(
			request,
			tokenObject(),
			(err: RpcError, response: ReadUserResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCReadUser = (
	userId: string
): Promise<ReadUserResponse.AsObject> => {
	const request = new ReadUserRequest();
	request.setUser(String(userId));

	return new Promise<ReadUserResponse.AsObject>((resolve, reject) => {
		userService.readUser(
			request,
			tokenObject(),
			(err: RpcError, response: ReadUserResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCReadUsers = (): Promise<ReadUsersResponse.AsObject> => {
	let request = new ReadUsersRequest();

	return new Promise<ReadUsersResponse.AsObject>((resolve, reject) => {
		userService.readUsers(
			request,
			tokenObject(),
			(err: RpcError, response: ReadUsersResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCUpdateUser = (
	user: User.AsObject
): Promise<User.AsObject | null> => {
	let request = new UpdateUserRequest();
	request.setUser(updateUserClass(user));

	return new Promise<User.AsObject | null>((resolve, reject) => {
		userService.updateUser(
			request,
			tokenObject(),
			(err: RpcError, response: UpdateUserResponse) => {
				if (err) {
					reject(err);
				} else {
					const updatedUser = response.getUser()?.toObject() || null;
					resolve(updatedUser);
				}
			}
		);
	});
};

export const gRPCDeleteUser = (
	userId: string
): Promise<DeleteUserResponse.AsObject> => {
	const request = new DeleteUserRequest();
	request.setId(userId);
	return new Promise<DeleteUserResponse.AsObject>((resolve, reject) => {
		userService.deleteUser(
			request,
			tokenObject(),
			(err: RpcError, response: DeleteUserResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCDeactivateUser = (
	data: string
): Promise<DeactivateUserResponse.AsObject> => {
	let req = new DeactivateUserRequest();
	req.setUserId(data);

	return new Promise<DeactivateUserResponse.AsObject>((resolve, reject) => {
		userService.deactivateUser(
			req,
			tokenObject(),
			(err: RpcError, response: DeactivateUserResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCActivateUser = (
	data: string
): Promise<DeactivateUserResponse.AsObject> => {
	let req = new DeactivateUserRequest();
	req.setUserId(data);

	return new Promise<DeactivateUserResponse.AsObject>((resolve, reject) => {
		userService.activateUser(
			req,
			tokenObject(),
			(err: RpcError, response: DeactivateUserResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPClogin = (
	credentials: LoginRequest.AsObject
): Promise<LoginResponse.AsObject> => {
	let req = new LoginRequest();
	req.setUsername(credentials.username ?? "");
	req.setPassword(credentials.password ?? "");
	req.setEmail(credentials.email ?? "");

	return new Promise<LoginResponse.AsObject>((resolve, reject) => {
		authService.login(
			req,
			undefined,
			(err: RpcError, response: LoginResponse) => {
				if (err) {
					console.log("Metadata:", err?.metadata);
					reject(err);
				} else {
					console.log("Response:", response);
					resolve(response.toObject());
				}
			}
		);
	});
};

export const logout = (token: string): Promise<LogoutResponse.AsObject> => {
	let req = new LogoutRequest();
	req.setAccessToken(token);

	return new Promise<LogoutResponse.AsObject>((resolve, reject) => {
		authService.logout(
			req,
			undefined,
			(err: RpcError, response: LogoutResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCChangePassword = (
	data: ChangePasswordRequest.AsObject
): Promise<ChangePasswordResponse.AsObject> => {
	let req = new ChangePasswordRequest();
	req.setOldPassword(data.oldPassword);
	req.setNewPassword(data.newPassword);

	return new Promise<ChangePasswordResponse.AsObject>((resolve, reject) => {
		authService.changePassword(
			req,
			tokenObject(),
			(err: RpcError, response: ChangePasswordResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCForgotPassword = (
	email: string
): Promise<ForgotPasswordResponse.AsObject> => {
	let req = new ForgotPasswordRequest();
	req.setEmail(email);

	return new Promise<ForgotPasswordResponse.AsObject>((resolve, reject) => {
		authService.forgotPassword(
			req,
			undefined,
			(err: RpcError, response: ForgotPasswordResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCCreateRole = (
	data: Role.AsObject
): Promise<CreateRoleResponse.AsObject> => {
	let req = new CreateRoleRequest();
	req.setRole(newRoleClass(data));

	return new Promise<CreateRoleResponse.AsObject>((resolve, reject) => {
		roleService.createRole(
			req,
			tokenObject(),
			(err: RpcError, response: CreateRoleResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCUpdateRole = (
	data: Role.AsObject
): Promise<UpdateRoleResponse.AsObject> => {
	let req = new UpdateRoleRequest();
	req.setRole(newRoleClass(data));

	return new Promise<UpdateRoleResponse.AsObject>((resolve, reject) => {
		roleService.updateRole(
			req,
			undefined,
			(err: RpcError, response: UpdateRoleResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCReadRole = (
	roleId: string
): Promise<ReadRoleResponse.AsObject> => {
	let req = new ReadRoleRequest();
	req.setId(roleId);

	return new Promise<ReadRoleResponse.AsObject>((resolve, reject) => {
		roleService.readRole(
			req,
			tokenObject(),
			(err: RpcError, response: ReadRoleResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCReadRoles = (): Promise<ReadRolesResponse.AsObject> => {
	let req = new ReadRolesRequest();

	return new Promise<ReadRolesResponse.AsObject>((resolve, reject) => {
		roleService.readRoles(
			req,
			tokenObject(),
			(err: RpcError, response: ReadRolesResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readUserRoles = (
	data: string
): Promise<ReadRolesResponse.AsObject> => {
	let req = new ReadRolesRequest();
	req.setId(data);
	return new Promise<ReadRolesResponse.AsObject>((resolve, reject) => {
		roleService.readUserRoles(
			req,
			tokenObject(),
			(err: RpcError, response: ReadRolesResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCDeleteRole = (
	roleId: string
): Promise<DeleteRoleResponse.AsObject> => {
	let req = new DeleteRoleRequest();
	req.setId(roleId);

	return new Promise<DeleteRoleResponse.AsObject>((resolve, reject) => {
		roleService.deleteRole(
			req,
			tokenObject(),
			(err: RpcError, response: DeleteRoleResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCReadPermissions =
	(): Promise<ReadPermissionsResponse.AsObject> => {
		const req = new ReadPermissionsRequest();

		return new Promise<ReadPermissionsResponse.AsObject>((resolve, reject) => {
			permissionService.readPermissions(
				req,
				tokenObject(),
				(err: RpcError, response: ReadPermissionsResponse) => {
					if (err) {
						reject(err);
					} else {
						resolve(response.toObject());
					}
				}
			);
		});
	};

export const gRPCAddPermissionToRole = (
	roleId: string,
	permissionCodenames: string[]
): Promise<PermissionToRoleResponse.AsObject> => {
	const req = new PermissionToRoleRequest();
	req.setRoleId(roleId);
	req.setPermissionCodenamesList(permissionCodenames);

	return new Promise<PermissionToRoleResponse.AsObject>((resolve, reject) => {
		roleService.addPermissionToRole(
			req,
			tokenObject(),
			(err: RpcError, response: PermissionToRoleResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCRemovePermissionFromRole = (
	roleId: string,
	permissionCodenames: string[]
): Promise<PermissionToRoleResponse.AsObject> => {
	const req = new PermissionToRoleRequest();
	req.setRoleId(roleId);
	req.setPermissionCodenamesList(permissionCodenames);

	return new Promise<PermissionToRoleResponse.AsObject>((resolve, reject) => {
		roleService.removePermissionFromRole(
			req,
			tokenObject(),
			(err: RpcError, response: PermissionToRoleResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCRemoveRoleFromUser = (
	data: RoleToUserRequest.AsObject
): Promise<RoleToUserResponse.AsObject> => {
	let req = new RoleToUserRequest();
	req.setUserId(data.userId);
	req.setRoleId(data.roleId);

	return new Promise<RoleToUserResponse.AsObject>((resolve, reject) => {
		userService.removeRoleFromUser(
			req,
			tokenObject(),
			(err: RpcError, response: RoleToUserResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const gRPCAddRoleToUser = (
	data: RoleToUserRequest.AsObject
): Promise<RoleToUserResponse.AsObject> => {
	let req = new RoleToUserRequest();
	req.setUserId(data.userId);
	req.setRoleId(data.roleId);

	return new Promise<RoleToUserResponse.AsObject>((resolve, reject) => {
		userService.addRoleToUser(
			req,
			tokenObject(),
			(err: RpcError, response: RoleToUserResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const createContact = (
	data: Contact.AsObject
): Promise<CreateContactResponse.AsObject> => {
	let req = new CreateContactRequest();
	req.setContact(newContactClass(data));

	return new Promise<CreateContactResponse.AsObject>((resolve, reject) => {
		contactService.createContact(
			req,
			tokenObject(),
			(err: RpcError, response: CreateContactResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const updateContact = (
	data: Contact.AsObject
): Promise<CreateContactResponse.AsObject> => {
	let req = new UpdateContactRequest();
	req.setContact(newContactClass(data));

	return new Promise<CreateContactResponse.AsObject>((resolve, reject) => {
		contactService.updateContact(
			req,
			tokenObject(),
			(err: RpcError, response: CreateContactResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readContact = (
	contactId: string
): Promise<ReadContactResponse.AsObject> => {
	let req = new ReadContactRequest();
	req.setContactId(contactId);

	return new Promise<ReadContactResponse.AsObject>((resolve, reject) => {
		contactService.readContact(
			req,
			tokenObject(),
			(err: RpcError, response: ReadContactResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readContacts = (
	organisationId: string,
	page: number
): Promise<ReadContactsResponse.AsObject> => {
	let req = new ReadContactsRequest();
	req.setOrganisationId(organisationId);
	req.setPage(page || 1);
	req.setPerPage(50);

	return new Promise<ReadContactsResponse.AsObject>((resolve, reject) => {
		contactService.readContacts(
			req,
			tokenObject(),
			(err: RpcError, response: ReadContactsResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const deleteContact = (
	contactId: string
): Promise<DeleteContactResponse.AsObject> => {
	let req = new DeleteContactRequest();
	req.setContactId(contactId);

	return new Promise<DeleteContactResponse.AsObject>((resolve, reject) => {
		contactService.deleteContact(
			req,
			tokenObject(),
			(err: RpcError, response: DeleteContactResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readCustomFieldDefinitions =
	(): Promise<ReadCustomFieldDefinitionsResponse.AsObject> => {
		let req = new ReadCustomFieldDefinitionsRequest();

		return new Promise<ReadCustomFieldDefinitionsResponse.AsObject>(
			(resolve, reject) => {
				contactService.readCustomFieldDefinitions(
					req,
					tokenObject(),
					(err: RpcError, response) => {
						if (err) {
							reject(err);
						} else {
							resolve(response.toObject());
						}
					}
				);
			}
		);
	};

export const bulkUploadContacts = (
	bytes: Uint8Array,
	group?: any
): Promise<BulkContactsResponse.AsObject> => {
	let req = new BulkContactsRequest();
	req.setData(bytes);
	group && req.setGroup(newContactGroupClass(group));

	return new Promise<BulkContactsResponse.AsObject>((resolve, reject) => {
		contactService.bulkContacts(
			req,
			tokenObject(),
			(err: RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const searchContacts = (
	query: string,
	page: number,
	groupId?: string,
	accountId?: string
): Promise<SearchContactsResponse.AsObject> => {
	let req = new SearchContactsRequest();
	req.setQuery(query);
	req.setPage(page || 1);
	req.setPerPage(50);
	if (groupId) req.setGroupId(groupId);
	if (accountId) req.setAccountId(accountId);

	const timeout = 300000; // 5 min
	const deadline = (Date.now() + timeout).toString();

	return new Promise<SearchContactsResponse.AsObject>((resolve, reject) => {
		contactService.searchContacts(
			req,
			{ ...tokenObject(), deadline: deadline },
			(err: RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const findContactByEmailOrPhone = (
	email?: string,
	phone?: string,
	organisationId?: string
): Promise<FindContactByEmailOrPhoneResponse.AsObject> => {
	let req = new FindContactByEmailOrPhoneRequest();
	req.setEmail(email || "");
	req.setPhone(phone || "");
	req.setOrganisationId(organisationId || "");

	return new Promise<FindContactByEmailOrPhoneResponse.AsObject>(
		(resolve, reject) => {
			contactService.findContactByEmailOrPhone(
				req,
				tokenObject(),
				(err: RpcError, response) => {
					if (err) {
						reject(err);
					} else {
						resolve(response.toObject());
					}
				}
			);
		}
	);
};

export const ReadContactChildren = (contactId: string) => {
	let req = new ReadContactChildrenRequest();
	req.setContactId(contactId);

	return new Promise<ReadContactChildrenResponse.AsObject>(
		(resolve, reject) => {
			contactService.readContactChildren(
				req,
				tokenObject(),
				(err: RpcError, response: ReadContactChildrenResponse) => {
					if (err) {
						reject(err);
					} else {
						resolve(response.toObject());
					}
				}
			);
		}
	);
};

export const readAccountContacts = (
	accountId: string,
	page: number
): Promise<ReadAccountContactsResponse.AsObject> => {
	let req = new ReadAccountContactsRequest();
	req.setPage(page || 1);
	req.setPerPage(50);
	req.setAccountId(accountId);

	return new Promise<ReadAccountContactsResponse.AsObject>(
		(resolve, reject) => {
			contactService.readAccountContacts(
				req,
				tokenObject(),
				(err: RpcError, response: ReadAccountContactsResponse) => {
					if (err) {
						reject(err);
					} else {
						resolve(response.toObject());
					}
				}
			);
		}
	);
};

export const UploadDocument = (
	file: File,
	fileName: string
): Promise<UploadDocumentResponse.AsObject> => {
	let req = new UploadDocumentRequest();
	req.setDocument(newUploadDocumentClass(file, fileName));

	return new Promise<UploadDocumentResponse.AsObject>((resolve, reject) => {
		contactService.uploadDocument(
			req,
			undefined,
			(err: RpcError, response: UploadDocumentResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const createContactGroup = (
	data: Group.AsObject
): Promise<CreateGroupResponse.AsObject> => {
	let req = new CreateGroupRequest();
	req.setGroup(newContactGroupClass(data));

	return new Promise<CreateGroupResponse.AsObject>((resolve, reject) => {
		contactGroupService.createGroup(
			req,
			tokenObject(),
			(err: RpcError, response: CreateGroupResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const updateGroup = (
	data: Group.AsObject
): Promise<UpdateGroupResponse.AsObject> => {
	let req = new UpdateGroupRequest();
	req.setGroup(newContactGroupClass(data));

	return new Promise<UpdateGroupResponse.AsObject>((resolve, reject) => {
		contactGroupService.updateGroup(
			req,
			tokenObject(),
			(err: RpcError, response: UpdateGroupResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readGroup = (
	groupId: string
): Promise<ReadGroupResponse.AsObject> => {
	let req = new ReadGroupRequest();
	req.setId(groupId);

	return new Promise<ReadGroupResponse.AsObject>((resolve, reject) => {
		contactGroupService.readGroup(
			req,
			undefined,
			(err: RpcError, response: ReadGroupResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readGroups = (
	organisationId: string,
	branchId: string,
	departmentId: string,
	page: number
): Promise<ReadGroupsResponse.AsObject> => {
	let req = new ReadGroupsRequest();
	req.setOrganisationId(organisationId);
	req.setBranchId(branchId);
	req.setDepartmentId(departmentId);
	req.setPage(page || 1);
	req.setPerPage(50);

	return new Promise<ReadGroupsResponse.AsObject>((resolve, reject) => {
		contactGroupService.readGroups(
			req,
			tokenObject(),
			(err: RpcError, response: ReadGroupsResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const deleteGroup = (
	groupId: string
): Promise<DeleteGroupResponse.AsObject> => {
	let req = new DeleteGroupRequest();
	req.setId(groupId);

	return new Promise<DeleteGroupResponse.AsObject>((resolve, reject) => {
		contactGroupService.deleteGroup(
			req,
			tokenObject(),
			(err: RpcError, response: DeleteGroupResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const ReadGroupContacts = (
	groupId: string
): Promise<ReadGroupContactsResponse.AsObject> => {
	let req = new ReadGroupContactsRequest();
	req.setGroupId(groupId);

	return new Promise<ReadGroupContactsResponse.AsObject>((resolve, reject) => {
		contactGroupService.readGroupContacts(
			req,
			tokenObject(),
			(err: RpcError, response: ReadGroupContactsResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const createTag = (
	data: Tag.AsObject
): Promise<CreateTagResponse.AsObject> => {
	let req = new CreateTagRequest();
	req.setTag(newTagClass(data));

	return new Promise<CreateTagResponse.AsObject>((resolve, reject) => {
		tagService.createTag(
			req,
			tokenObject(),
			(err: RpcError, response: CreateTagResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readTag = (): Promise<ReadTagResponse.AsObject> => {
	let req = new ReadTagRequest();

	return new Promise<ReadTagResponse.AsObject>((resolve, reject) => {
		tagService.readTag(
			req,
			tokenObject(),
			(err: RpcError, response: ReadTagResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const updateTag = (
	data: Tag.AsObject
): Promise<UpdateTagResponse.AsObject> => {
	let req = new UpdateTagRequest();
	req.setTag(newTagClass(data));

	return new Promise<UpdateTagResponse.AsObject>((resolve, reject) => {
		tagService.updateTag(
			req,
			tokenObject(),
			(err: RpcError, response: UpdateTagResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readTags = (): Promise<ReadTagsResponse.AsObject> => {
	let req = new ReadTagsRequest();

	return new Promise<ReadTagsResponse.AsObject>((resolve, reject) => {
		tagService.readTags(
			req,
			tokenObject(),
			(err: RpcError, response: ReadTagsResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const deleteTag = (
	tagId: string
): Promise<DeleteTagResponse.AsObject> => {
	let req = new DeleteTagRequest();
	req.setId(tagId);

	return new Promise<DeleteTagResponse.AsObject>((resolve, reject) => {
		tagService.deleteTag(
			req,
			tokenObject(),
			(err: RpcError, response: DeleteTagResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const createLabel = (
	data: Label.AsObject
): Promise<CreateLabelResponse.AsObject> => {
	let req = new CreateLabelRequest();
	req.setLabel(newLabelClass(data));

	return new Promise<CreateLabelResponse.AsObject>((resolve, reject) => {
		labelService.createLabel(
			req,
			tokenObject(),
			(err: RpcError, response: CreateLabelResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readLabel = (): Promise<ReadLabelResponse.AsObject> => {
	let req = new ReadLabelRequest();

	return new Promise<ReadLabelResponse.AsObject>((resolve, reject) => {
		labelService.readLabel(
			req,
			tokenObject(),
			(err: RpcError, response: ReadLabelResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const updateLabel = (
	data: Label.AsObject
): Promise<UpdateLabelResponse.AsObject> => {
	let req = new UpdateLabelRequest();
	req.setLabel(newLabelClass(data));

	return new Promise<UpdateLabelResponse.AsObject>((resolve, reject) => {
		labelService.updateLabel(
			req,
			tokenObject(),
			(err: RpcError, response: UpdateLabelResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readLabels = (): Promise<ReadLabelsResponse.AsObject> => {
	let req = new ReadLabelsRequest();

	return new Promise<ReadLabelsResponse.AsObject>((resolve, reject) => {
		labelService.readLabels(
			req,
			tokenObject(),
			(err: RpcError, response: ReadLabelsResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const deleteLabel = (
	labelId: string
): Promise<DeleteLabelResponse.AsObject> => {
	let req = new DeleteLabelRequest();
	req.setId(labelId);

	return new Promise<DeleteLabelResponse.AsObject>((resolve, reject) => {
		labelService.deleteLabel(
			req,
			tokenObject(),
			(err: RpcError, response: DeleteLabelResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readBranch = (
	branchId: string
): Promise<ReadBranchResponse.AsObject> => {
	let req = new ReadBranchRequest();
	req.setId(branchId);

	return new Promise<ReadBranchResponse.AsObject>((resolve, reject) => {
		branchService.readBranch(
			req,
			tokenObject(),
			(err: RpcError, response: ReadBranchResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readBranches = (): Promise<ReadBranchesResponse.AsObject> => {
	let req = new ReadBranchesRequest();

	return new Promise<ReadBranchesResponse.AsObject>((resolve, reject) => {
		branchService.readBranches(
			req,
			tokenObject(),
			(err: RpcError, response: ReadBranchesResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readDepartment = (
	departmentId: string
): Promise<ReadDepartmentResponse.AsObject> => {
	let req = new ReadDepartmentRequest();
	req.setId(departmentId);

	return new Promise<ReadDepartmentResponse.AsObject>((resolve, reject) => {
		departmentService.readDepartment(
			req,
			tokenObject(),
			(err: RpcError, response: ReadDepartmentResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readDepartments =
	(): Promise<ReadDepartmentsResponse.AsObject> => {
		let req = new ReadDepartmentsRequest();

		return new Promise<ReadDepartmentsResponse.AsObject>((resolve, reject) => {
			departmentService.readDepartments(
				req,
				tokenObject(),
				(err: RpcError, response: ReadDepartmentsResponse) => {
					if (err) {
						reject(err);
					} else {
						resolve(response.toObject());
					}
				}
			);
		});
	};

export const ReadApps = (): Promise<ReadAppsResponse.AsObject> => {
	let req = new App();
	return new Promise<ReadAppsResponse.AsObject>((resolve, reject) => {
		appService.readApps(
			req,
			tokenObject(),
			(err: RpcError, response: ReadAppsResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const ReadApp = (appId: string): Promise<ReadAppResponse.AsObject> => {
	let req = new App();
	req.setId(appId);
	return new Promise<ReadAppResponse.AsObject>((resolve, reject) => {
		appService.readApp(
			req,
			tokenObject(),
			(err: RpcError, response: ReadAppResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const CreateApp = (
	data: App.AsObject
): Promise<CreateAppResponse.AsObject> => {
	let req = new CreateAppRequest();
	req.setApp(newAppClass(data));
	return new Promise<CreateAppResponse.AsObject>((resolve, reject) => {
		appService.createApp(
			req,
			tokenObject(),
			(err: RpcError, response: CreateAppResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const UseAppTemplate = (
	id: string
): Promise<CreateAppWithTemplateResponse.AsObject> => {
	let req = new CreateAppWithTemplateRequest();
	req.setTemplateAppId(id);
	return new Promise<CreateAppWithTemplateResponse.AsObject>(
		(resolve, reject) => {
			appService.createAppWithTemplate(
				req,
				tokenObject(),
				(err: RpcError, response: CreateAppWithTemplateResponse) => {
					if (err) {
						reject(err);
					} else {
						resolve(response.toObject());
					}
				}
			);
		}
	);
};

export const ReadTemplates = (): Promise<ReadAppTemplatesResponse.AsObject> => {
	let req: ReadAppTemplatesRequest = new ReadAppTemplatesRequest();
	return new Promise<ReadAppTemplatesResponse.AsObject>((resolve, reject) => {
		appService.readAppTemplates(
			req,
			tokenObject(),
			(err: RpcError, response: ReadAppTemplatesResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const UpdateApp = (
	data: App.AsObject
): Promise<UpdateAppResponse.AsObject> => {
	let req = new UpdateAppRequest();
	req.setApp(newAppClass(data));
	return new Promise<UpdateAppResponse.AsObject>((resolve, reject) => {
		appService.updateApp(
			req,
			tokenObject(),
			(err: RpcError, response: UpdateAppResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const DeleteApp = (
	appId: string
): Promise<DeleteAppResponse.AsObject> => {
	let req = new App();
	req.setId(appId);
	return new Promise<DeleteAppResponse.AsObject>((resolve, reject) => {
		appService.deleteApp(
			req,
			tokenObject(),
			(err: RpcError, response: DeleteAppResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const searchUsers = (
	query: string,
	page: number
): Promise<SearchUsersResponse.AsObject> => {
	let req = new SearchUsersRequest();
	req.setQuery(query);
	req.setPage(page || 1);
	req.setPerPage(50);

	return new Promise<SearchUsersResponse.AsObject>((resolve, reject) => {
		userService.searchUsers(req, tokenObject(), (err: RpcError, response) => {
			if (err) {
				reject(err);
			} else {
				resolve(response.toObject());
			}
		});
	});
};
