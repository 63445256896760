import { Box, Divider, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { RpcError } from "grpc-web";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { surveyDummy } from "../../../assets/data";
import { notify } from "../../../gateway/Functions";
import {
  createSurveyResponse,
  getSurveyById,
} from "../../../gateway/gRPC/Utils/SurveyMethods";
import {
  getSurveyQuestions,
  sortQuestionsByIndex,
} from "../../../gateway/gRPC/Utils/utils";
import {
  Answer,
  CreateResponseResponse,
  Question,
  Response,
  Survey,
} from "../../../gateway/gRPC/pb/surveys_pb";
import strings from "../../../localization/main";
import { primaryGrayDark } from "../../../theme/theme";
import CustomTextButton from "../../buttons/CustomTextButton";
import PageContainer from "../../containers/PageContainer";
import BongaCard from "../../general/Card";
import CustomGeneralTag from "../../general/CustomGeneralTag";
import { SubHeading } from "../../headings/Headings";
import SurveyQuestion from "../questionnaire/Question";
import CustomPageHeader from "../../headers/CustomPageHeader";
import { User } from "../../../gateway/gRPC/pb/user_pb";

export default function SurveyResponse() {
  const loggedIn = useSelector((state: any) => state.LoggedIn);
  const user: User.AsObject = useSelector((state: any) => state.Profile);
  const [loading, setLoading] = useState<boolean>(false);
  const [survey, setSurvey] = useState<Survey.AsObject>(surveyDummy);
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [answers, setAnswers] = useState<Answer.AsObject[]>([]);
  const response: Response.AsObject = {
    id: "",
    serialNumber: "",
    answersList: [],
    surveyId: surveyId!,
    averageResponseTime: 0,
  };

  useEffect(() => {
    setLoading(true);
    if (surveyId) {
      getSurveyById(surveyId)
        .then((res) => {
          if (res.survey) {
            setSurvey(res.survey);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [surveyId]);
  const handelAnswer = <T,>(questionId: string, answerTypeData: T) => {
    setAnswers((prevState: Answer.AsObject[]) => [
      ...prevState.map((answer: Answer.AsObject) => {
        if (answer.questionId === questionId) {
          return {
            ...answer,
            ...answerTypeData,
          };
        }
        return answer;
      }),
    ]);
  };
  useEffect(() => {
    handleFormulateQuestionAnswers(getSurveyQuestions(survey));
  }, [survey]);

  const handleFormulateQuestionAnswers = (questions: Question.AsObject[]) => {
    const answersList: Answer.AsObject[] = [];
    questions.forEach((question: Question.AsObject) => {
      const answer: Answer.AsObject = {
        questionId: question.id,
        questionType: question.type,
        surveyId: question.surveyId,
        responseId: "",
        id: "",
      };
      answersList.push(answer);
    });
    setAnswers(answersList);
  };
  const handleSurveySubmit = () => {
    setLoading(true);
    const newResponse: Response.AsObject = {
      ...response,
      respondent: {
        id: `${user.id}`,
        name: user.username,
        email: user.email,
        phoneNumber: user.phone,
      },
      answersList: answers,
    };

    createSurveyResponse(newResponse)
      .then((res: CreateResponseResponse.AsObject) => {
        notify(
          "success",
          "bottomLeft",
          "Success,",
          res.status?.message ?? "Submited response successfully"
        );
        navigate(`/apps/surveys/${res.response?.id}/response_submitted`);
      })
      .catch((err: RpcError) => {
        notify(
          "error",
          "bottomLeft",
          "Oops! Something went wrong.",
          err.message
        );
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <PageContainer
      h={loggedIn ? "auto" : "100vh"}
      overflowY={"scroll"}
      p={loggedIn ? ".5rem" : "3rem 10rem"}
    >
      <CustomPageHeader title={""} closable />
      <BongaCard loading={loading} p={"1rem"} h={"auto"}>
        <HStack spacing={4} align="center" justify="space-between" width="100%">
          <Box>
            <Text fontSize="lg" fontWeight="bold">
              {survey?.title}
            </Text>
            <Text>{survey?.description}</Text>
          </Box>
          <CustomGeneralTag
            text={`
            ${
              survey?.maxResponses > 0 &&
              survey?.responseCount <= survey?.maxResponses
                ? "ACTIVE"
                : "CLOSED"
            }`}
            colorScheme={`${
              survey?.maxResponses > 0 &&
              survey?.responseCount <= survey?.maxResponses
                ? "green"
                : "orange"
            }`}
            size="xs"
            weight="bold"
          />
        </HStack>
        <Divider my=".5rem" />
        <VStack
          spacing={"1.5rem"}
          w={"100%"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          {survey?.sectionsList.map((section, index) => (
            <VStack
              spacing={".5rem"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              key={index}
              w={"100%"}
            >
              <Box p={".5rem"} w={"100%"} bg={primaryGrayDark}>
                <SubHeading>{`${strings.section}-${section.index}: ${section.title}`}</SubHeading>
                <Text>{section.description}</Text>
              </Box>
              {sortQuestionsByIndex(section.questionsList).map(
                (question: Question.AsObject, index: number) => (
                  <SurveyQuestion
                    key={`${index}-Question-${question.index}`}
                    question={question}
                    isResponse
                    handleAnswer={handelAnswer}
                  />
                )
              )}
            </VStack>
          ))}
        </VStack>
        <HStack
          spacing={4}
          align="center"
          justify="space-between"
          width="100%"
          py={".5rem"}
        >
          <Spacer />
          <CustomTextButton
            onClick={handleSurveySubmit}
            colorScheme={"blue"}
            fontWeight={"bold"}
          >
            <MdAdd />
            {"Submit Survey"}
          </CustomTextButton>
        </HStack>
      </BongaCard>
    </PageContainer>
  );
}
