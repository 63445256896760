import { IpoActiveCallState, IpoActiveCallStateAction } from "../../components/avaya/ipo/IpoDataTypes"
import { REMOVE_AVAYA_ACTIVE_CALL, SET_AVAYA_ACTIVE_CALL, UPDATE_AVAYA_SESSION_STATE } from "../actions/actions"

const initialState: IpoActiveCallState = {
    call: null,
    notify: false,
    direction: "",
    sessionState: "Initial",
}
export default function ActiveIpoCall(state: IpoActiveCallState = initialState, action: IpoActiveCallStateAction): IpoActiveCallState {
    switch (action.type) {
        case SET_AVAYA_ACTIVE_CALL:
            return { ...action.payload }
        case REMOVE_AVAYA_ACTIVE_CALL:
            return { ...initialState }
            case UPDATE_AVAYA_SESSION_STATE:
            return { ...state, sessionState: action.payload.sessionState }
        default:
            return state
    }
}
