import {
  RESET_ACTIVE_CALL_NUMBER,
  SET_ACTIVE_CALL_NUMBER,
} from "../actions/actions";

export default function ActiveNumber(state: string = "", action: any) {
  switch (action.type) {
    case SET_ACTIVE_CALL_NUMBER:
      return action.payload;
    case RESET_ACTIVE_CALL_NUMBER:
      return "";
    default:
      return state;
  }
}
