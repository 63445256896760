import { Button, Tooltip } from "@chakra-ui/react";
import { RiDeleteBin2Line } from "react-icons/ri";

interface EditButtonProps {
	tooltipLabel?: string;
	hasArrow?: boolean;

	[key: string]: any;
}

function CustomDeleteButton({ tooltipLabel, placement, hasArrow, ...rest }: EditButtonProps) {
	return (
		<Tooltip
			label={tooltipLabel}
			placement={placement}
			hasArrow={hasArrow}
		>
			<Button
				placeItems={"center"}
				bg={"gray.200"}
				borderRadius={"3px"}
				_hover={{ bg: "gray.300" }}
				color={"gray.700"}
				size={"xs"}
				{...rest}
			>
				<RiDeleteBin2Line />
			</Button>
		</Tooltip>
	);
}

export default CustomDeleteButton;
