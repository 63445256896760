/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: whatsapp_campaigns.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.proto = require('./whatsapp_campaigns_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.WhatsappServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.WhatsappServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateWhatsappTemplateRequest,
 *   !proto.da.proto.CreateWhatsappTemplateResponse>}
 */
const methodDescriptor_WhatsappService_CreateWhatsappTemplate = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/CreateWhatsappTemplate',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateWhatsappTemplateRequest,
  proto.da.proto.CreateWhatsappTemplateResponse,
  /**
   * @param {!proto.da.proto.CreateWhatsappTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateWhatsappTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateWhatsappTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateWhatsappTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.createWhatsappTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/CreateWhatsappTemplate',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_CreateWhatsappTemplate,
      callback);
};


/**
 * @param {!proto.da.proto.CreateWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateWhatsappTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.createWhatsappTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/CreateWhatsappTemplate',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_CreateWhatsappTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ViewWhatsappTemplateRequest,
 *   !proto.da.proto.ViewWhatsappTemplateResponse>}
 */
const methodDescriptor_WhatsappService_ViewWhatsappTemplate = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/ViewWhatsappTemplate',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ViewWhatsappTemplateRequest,
  proto.da.proto.ViewWhatsappTemplateResponse,
  /**
   * @param {!proto.da.proto.ViewWhatsappTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ViewWhatsappTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ViewWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ViewWhatsappTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ViewWhatsappTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.viewWhatsappTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/ViewWhatsappTemplate',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ViewWhatsappTemplate,
      callback);
};


/**
 * @param {!proto.da.proto.ViewWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ViewWhatsappTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.viewWhatsappTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/ViewWhatsappTemplate',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ViewWhatsappTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListWhatsappTemplateRequest,
 *   !proto.da.proto.ListWhatsappTemplateResponse>}
 */
const methodDescriptor_WhatsappService_ListWhatsappTemplates = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/ListWhatsappTemplates',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListWhatsappTemplateRequest,
  proto.da.proto.ListWhatsappTemplateResponse,
  /**
   * @param {!proto.da.proto.ListWhatsappTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListWhatsappTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListWhatsappTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListWhatsappTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.listWhatsappTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/ListWhatsappTemplates',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ListWhatsappTemplates,
      callback);
};


/**
 * @param {!proto.da.proto.ListWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListWhatsappTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.listWhatsappTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/ListWhatsappTemplates',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ListWhatsappTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateWhatsappCampaignRequest,
 *   !proto.da.proto.CreateWhatsappCampaignResponse>}
 */
const methodDescriptor_WhatsappService_CreateWhatsappCampaign = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/CreateWhatsappCampaign',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateWhatsappCampaignRequest,
  proto.da.proto.CreateWhatsappCampaignResponse,
  /**
   * @param {!proto.da.proto.CreateWhatsappCampaignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateWhatsappCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateWhatsappCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateWhatsappCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateWhatsappCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.createWhatsappCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/CreateWhatsappCampaign',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_CreateWhatsappCampaign,
      callback);
};


/**
 * @param {!proto.da.proto.CreateWhatsappCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateWhatsappCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.createWhatsappCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/CreateWhatsappCampaign',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_CreateWhatsappCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ViewWhatsappCampaignRequest,
 *   !proto.da.proto.ViewWhatsappCampaignResponse>}
 */
const methodDescriptor_WhatsappService_ViewWhatsappCampaign = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/ViewWhatsappCampaign',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ViewWhatsappCampaignRequest,
  proto.da.proto.ViewWhatsappCampaignResponse,
  /**
   * @param {!proto.da.proto.ViewWhatsappCampaignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ViewWhatsappCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ViewWhatsappCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ViewWhatsappCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ViewWhatsappCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.viewWhatsappCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/ViewWhatsappCampaign',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ViewWhatsappCampaign,
      callback);
};


/**
 * @param {!proto.da.proto.ViewWhatsappCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ViewWhatsappCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.viewWhatsappCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/ViewWhatsappCampaign',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ViewWhatsappCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListWhatsappCampaignRequest,
 *   !proto.da.proto.ListWhatsappCampaignResponse>}
 */
const methodDescriptor_WhatsappService_ListWhatsappCampaigns = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/ListWhatsappCampaigns',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListWhatsappCampaignRequest,
  proto.da.proto.ListWhatsappCampaignResponse,
  /**
   * @param {!proto.da.proto.ListWhatsappCampaignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListWhatsappCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListWhatsappCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListWhatsappCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListWhatsappCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.listWhatsappCampaigns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/ListWhatsappCampaigns',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ListWhatsappCampaigns,
      callback);
};


/**
 * @param {!proto.da.proto.ListWhatsappCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListWhatsappCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.listWhatsappCampaigns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/ListWhatsappCampaigns',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ListWhatsappCampaigns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ViewWhatsappCampaignAnalyticsRequest,
 *   !proto.da.proto.ViewWhatsappCampaignAnalyticsResponse>}
 */
const methodDescriptor_WhatsappService_ViewWhatsappCampaignAnalytics = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/ViewWhatsappCampaignAnalytics',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ViewWhatsappCampaignAnalyticsRequest,
  proto.da.proto.ViewWhatsappCampaignAnalyticsResponse,
  /**
   * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ViewWhatsappCampaignAnalyticsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.viewWhatsappCampaignAnalytics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/ViewWhatsappCampaignAnalytics',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ViewWhatsappCampaignAnalytics,
      callback);
};


/**
 * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.viewWhatsappCampaignAnalytics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/ViewWhatsappCampaignAnalytics',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_ViewWhatsappCampaignAnalytics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteWhatsappTemplateRequest,
 *   !proto.da.proto.DeleteWhatsappTemplateResponse>}
 */
const methodDescriptor_WhatsappService_DeleteWhatsappTemplate = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/DeleteWhatsappTemplate',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteWhatsappTemplateRequest,
  proto.da.proto.DeleteWhatsappTemplateResponse,
  /**
   * @param {!proto.da.proto.DeleteWhatsappTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteWhatsappTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteWhatsappTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteWhatsappTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.deleteWhatsappTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/DeleteWhatsappTemplate',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_DeleteWhatsappTemplate,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteWhatsappTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.deleteWhatsappTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/DeleteWhatsappTemplate',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_DeleteWhatsappTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateWhatsappTemplateRequest,
 *   !proto.da.proto.UpdateWhatsappTemplateResponse>}
 */
const methodDescriptor_WhatsappService_UpdateWhatsappTemplate = new grpc.web.MethodDescriptor(
  '/da.proto.WhatsappService/UpdateWhatsappTemplate',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateWhatsappTemplateRequest,
  proto.da.proto.UpdateWhatsappTemplateResponse,
  /**
   * @param {!proto.da.proto.UpdateWhatsappTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateWhatsappTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateWhatsappTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateWhatsappTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WhatsappServiceClient.prototype.updateWhatsappTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WhatsappService/UpdateWhatsappTemplate',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_UpdateWhatsappTemplate,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateWhatsappTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateWhatsappTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WhatsappServicePromiseClient.prototype.updateWhatsappTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WhatsappService/UpdateWhatsappTemplate',
      request,
      metadata || {},
      methodDescriptor_WhatsappService_UpdateWhatsappTemplate);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.WebhookServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.WebhookServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.WhatsappTemplateStatusUpdateRequest,
 *   !proto.da.proto.UpdateWhatsappTemplateResponse>}
 */
const methodDescriptor_WebhookService_UpdateTemplateStatus = new grpc.web.MethodDescriptor(
  '/da.proto.WebhookService/UpdateTemplateStatus',
  grpc.web.MethodType.UNARY,
  proto.da.proto.WhatsappTemplateStatusUpdateRequest,
  proto.da.proto.UpdateWhatsappTemplateResponse,
  /**
   * @param {!proto.da.proto.WhatsappTemplateStatusUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateWhatsappTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.WhatsappTemplateStatusUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateWhatsappTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateWhatsappTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WebhookServiceClient.prototype.updateTemplateStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WebhookService/UpdateTemplateStatus',
      request,
      metadata || {},
      methodDescriptor_WebhookService_UpdateTemplateStatus,
      callback);
};


/**
 * @param {!proto.da.proto.WhatsappTemplateStatusUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateWhatsappTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WebhookServicePromiseClient.prototype.updateTemplateStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WebhookService/UpdateTemplateStatus',
      request,
      metadata || {},
      methodDescriptor_WebhookService_UpdateTemplateStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateWhatsappAnalyticsRequest,
 *   !proto.da.proto.UpdateWhatsappAnalyticsResponse>}
 */
const methodDescriptor_WebhookService_UpdateWhatsappCamapignAnalytics = new grpc.web.MethodDescriptor(
  '/da.proto.WebhookService/UpdateWhatsappCamapignAnalytics',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateWhatsappAnalyticsRequest,
  proto.da.proto.UpdateWhatsappAnalyticsResponse,
  /**
   * @param {!proto.da.proto.UpdateWhatsappAnalyticsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateWhatsappAnalyticsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateWhatsappAnalyticsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateWhatsappAnalyticsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateWhatsappAnalyticsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.WebhookServiceClient.prototype.updateWhatsappCamapignAnalytics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.WebhookService/UpdateWhatsappCamapignAnalytics',
      request,
      metadata || {},
      methodDescriptor_WebhookService_UpdateWhatsappCamapignAnalytics,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateWhatsappAnalyticsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateWhatsappAnalyticsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.WebhookServicePromiseClient.prototype.updateWhatsappCamapignAnalytics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.WebhookService/UpdateWhatsappCamapignAnalytics',
      request,
      metadata || {},
      methodDescriptor_WebhookService_UpdateWhatsappCamapignAnalytics);
};


module.exports = proto.da.proto;

