import { ChakraProvider } from "@chakra-ui/react";
import { configureStore } from "@reduxjs/toolkit";
import "@stripe/stripe-js";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "reactflow/dist/style.css";
import { persistReducer, persistStore } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import App from "./App";
import LanguageProvider from "./localization/LanguageProvider";
import reducers from "./redux/reducers";
import "./theme/index.css";
import { theme } from "./theme/theme";
import "./theme/theme.less";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/tiptap/styles.css";

const persistConfig: PersistConfig<any> = {
	key: "root",
	storage,
	blacklist: [
		"PhoneInstances, PhoneState selectedAccount,selectedTicket,UpdateTicket,UpdateTables,ModalAlertReducer,PhoneConnect,ActiveCall,GlobalDialState,PhoneInstances,AvayaSessionId,AvayaExtension,AvayaOnlineAgents,ActiveIpoCall",
	],
};
const persistedReducers = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }),
});

const persistedStore = persistStore(store);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistedStore}>
				<ChakraProvider theme={theme}>
					<LanguageProvider>
						<MantineProvider>
							<App />
						</MantineProvider>
					</LanguageProvider>
				</ChakraProvider>
			</PersistGate>
		</Provider>
	</StrictMode>
);
