import {
  AiOutlineCloseCircle,
  AiOutlineForm,
  AiOutlineMessage,
  AiOutlineQuestionCircle,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import {
  BiBot,
  BiCalendarStar,
  BiMessageAltCheck,
  BiMessageAltDetail,
  BiMessageAltMinus,
  BiRadioCircleMarked,
  BiStats,
} from "react-icons/bi";
import { BsShieldLock, BsTextareaResize } from "react-icons/bs";
import { CgOrganisation } from "react-icons/cg";
import {
  FaCoins,
  FaFileInvoiceDollar,
  FaUserShield,
  FaUsers,
  FaUsersCog,
} from "react-icons/fa";
import { GiChoice, GiTicket } from "react-icons/gi";
import { GoChecklist } from "react-icons/go";
import {
  HiOutlineDocumentReport,
  HiPhone,
  HiPhoneIncoming,
  HiPhoneMissedCall,
  HiPhoneOutgoing,
} from "react-icons/hi";
import {
  MdOutlineCallEnd,
  MdOutlineHttp,
  MdOutlineMailOutline,
  MdOutlinePhone,
  MdOutlineTextFields,
  MdOutlineWebhook,
  MdPhoneMissed,
  MdSettingsPhone,
  MdSms,
} from "react-icons/md";
import {
  RiBuildingFill,
  RiCheckboxLine,
  RiOrganizationChart,
  RiRadioButtonLine,
  RiUser6Line,
  RiUserLine,
} from "react-icons/ri";
import { TbApiApp, TbForms, TbNumbers, TbSubtask } from "react-icons/tb";
import { Survey } from "../gateway/gRPC/pb/surveys_pb";
import strings from "../localization/main";
import {
  DIDDataType,
  DialPlanDataType,
  DialType,
  ExtDataType,
  StatCardDataType,
} from "../types/DataTypes";
import BrandIcon from "./BrandIcon";

export const crmOverViewStats: StatCardDataType[] = [
  {
    title: "Total accounts",
    value: "43",
    icon: <RiOrganizationChart />,
  },
  {
    title: "Total Leads",
    value: "1,200",
    icon: <BiStats />,
  },
  {
    title: "Total Contacts",
    value: "54",
    icon: <RiUser6Line />,
  },
  {
    title: "Total Deals",
    value: "4367",
    icon: <FaCoins />,
  },
];

export const ticketingOverViewStats: StatCardDataType[] = [
  {
    title: "New Tickets",
    value: "43",
    icon: <GiTicket />,
  },
  {
    title: "Open Tickets",
    value: "1,200",
    icon: <GiTicket />,
  },
  {
    title: "Closed Tickets",
    value: "54",
    icon: <GiTicket />,
  },
  {
    title: "Total Tickets",
    value: "4367",
    icon: <GiTicket />,
  },
];

export const liveReportStats: StatCardDataType[] = [
  {
    title: "Total Conversations",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Incoming Conversations",
    value: "0",
    icon: <HiPhoneIncoming />,
  },
  {
    title: "Abandon rate",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Engaged conversation",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Bot deflection rate",
    value: "0",
    icon: <BiBot />,
  },
  {
    title: "Bot escalation rate",
    value: "0",
    icon: <BiBot />,
  },
];

export const BotReportStats: StatCardDataType[] = [
  {
    title: "Total bot conversations",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Total bot sessions",
    value: "0",
    icon: <HiPhoneIncoming />,
  },
  {
    title: "Bot engagment rate",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Bot resolution rate",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Bot abandon rate",
    value: "0",
    icon: <BiBot />,
  },
  {
    title: "Bot escalation rate",
    value: "0",
    icon: <BiBot />,
  },
];

export const agentStats: StatCardDataType[] = [
  {
    title: "Agents logged in",
    value: "55",
    icon: <GiTicket />,
  },
  {
    title: "Session rejection rate",
    value: "13%",
    icon: <GiTicket />,
  },
  {
    title: "Avg session handle time",
    value: "1:0:3",
    icon: <GiTicket />,
  },
  {
    title: "Wrap up time",
    value: "55:13",
    icon: <GiTicket />,
  },
];

export const VoiceReportStats: StatCardDataType[] = [
  {
    title: "Incoming conversations",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Conversations in queue",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Longest wait time",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Avg speed to answer",
    value: "0",
    icon: <HiPhone />,
  },
  {
    title: "Abandoned rate",
    value: "0",
    icon: <HiPhoneMissedCall />,
  },
  {
    title: "Ongoing conversations",
    value: "0",
    icon: <HiPhoneOutgoing />,
  },
  {
    title: "Avg handle time",
    value: "0",
    icon: <HiPhone />,
  },
];

export const cmInboundStats: StatCardDataType[] = [
  {
    title: "Inbound Calls",
    value: "0",
    icon: <HiPhoneIncoming />,
    source: "call_detail/records/inbound/count/",
    filter: "call_detail/records/inbound/count/filter/",
  },
  {
    title: "Answered Calls",
    value: "0",
    icon: <HiPhone />,
    source: "call_detail/records/answered/inbound/count/",
    filter: "call_detail/records/answered/inbound/count/filter/",
  },
  {
    title: "Missed Calls",
    value: "0",
    icon: <HiPhoneMissedCall />,
    source: "call_detail/records/missed/inbound/count/",
    filter: "call_detail/records/missed/inbound/count/filter/",
  },
  {
    title: "Abandoned Calls",
    value: "0",
    icon: <MdOutlineCallEnd />,
    source: "call_detail/records/abandoned/inbound/count/",
    filter: "call_detail/records/abandoned/inbound/count/filter/",
  },
];
export const cmOutboundStats: StatCardDataType[] = [
  {
    title: "Outbound Calls",
    value: "0",
    icon: <HiPhoneOutgoing />,
    source: "call_detail/records/outbound/count/",
    filter: "call_detail/records/outbound/count/filter/",
  },
  {
    title: "Answered Calls",
    value: "0",
    icon: <HiPhone />,
    source: "call_detail/records/answered/outbound/count/",
    filter: "call_detail/records/answered/outbound/count/filter/",
  },
  {
    title: "Not Answered Calls",
    value: "0",
    icon: <HiPhoneMissedCall />,
    source: "call_detail/records/missed/outbound/count/",
    filter: "call_detail/records/missed/outbound/count/filter/",
  },
  {
    title: "Abandoned Calls",
    value: "0",
    icon: <MdOutlineCallEnd />,
    source: "call_detail/records/abandoned/outbound/count/",
    filter: "call_detail/records/abandoned/outbound/count/filter/",
  },
];
export const cmAnalyticsStats: StatCardDataType[] = [
  {
    title: "Total",
    value: "0",
    icon: <MdSettingsPhone />,
    source: "call_detail/records/count/all/",
    filter: "call_detail/records/all/count/filter/",
  },
  {
    title: "Inbound",
    value: "0",
    icon: <HiPhoneIncoming />,
    source: "call_detail/records/inbound/count/",
    filter: "call_detail/records/inbound/count/filter/",
  },
  {
    title: "Outbound",
    value: "0",
    icon: <HiPhoneOutgoing />,
    source: "call_detail/records/outbound/count/",
    filter: "call_detail/records/outbound/count/filter/",
  },
  {
    title: "Answered (Overall)",
    value: "0",
    icon: <HiPhone />,
    source: "call_detail/records/answered/total/count/",
    filter: "call_detail/records/answered/total/count/filter/",
  },
  {
    title: "Missed (Overall)",
    value: "0",
    icon: <MdPhoneMissed />,
    source: "call_detail/records/missed/total/count/",
    filter: "call_detail/records/missed/total/count/filter/",
  },
  {
    title: "Abandoned (Overall)",
    value: "0",
    icon: <MdOutlineCallEnd />,
    source: "call_detail/records/abandoned/total/count/",
    filter: "call_detail/records/abandoned/total/count/filter/",
  },
];

export const overViewStats: StatCardDataType[] = [
  {
    title: "Total Calls",
    value: "0",
    icon: <HiPhone />,
    source: "agent/calls/all/count/",
    filter: "agent/calls/all/count/filter/",
  },
  {
    title: "Total Inbound Calls",
    value: "0",
    icon: <HiPhoneIncoming />,
    source: "agent/calls/inbound/count/",
    filter: "agent/calls/inbound/count/filter/",
  },
  {
    title: "Total Outbound Calls",
    value: "0",
    icon: <HiPhoneOutgoing />,
    source: "agent/calls/outbound/count/",
    filter: "agent/calls/outbound/count/filter/",
  },
  {
    title: "Total Missed Calls",
    value: "0",
    icon: <HiPhoneMissedCall />,
    source: "agent/calls/missed/count/",
    filter: "agent/calls/missed/count/filter/",
  },
];

export const availableAgents = [
  { iconType: "ticket", count: 11, subtitle: "Agents on Tickets" },
  { iconType: "chat", count: 20, subtitle: "Agents on Chat" },
  { iconType: "phone", count: 30, subtitle: "Agents on Phone" },
];

export const callsActive = [
  { iconType: "phone", count: 6, subtitle: "Calls in Queue" },
  { iconType: "chat", count: 9, subtitle: "Calls in Converstion" },
  { iconType: "chat", count: 9, subtitle: "Calls in Converstion" },
];

export const callsData = [
  { label: "Total calls", value: 49 },
  { label: "Inbound calls", value: 29 },
  { label: "Answered calls", value: 16 },
  { label: "Missed calls", value: 3 },
];

export const ticketsData = [
  { label: "Open Tickets", value: 19 },
  { label: "Unassigned", value: 20 },
  { label: "Overdue", value: 6 },
  { label: "Due today", value: 4 },
];

export const chatData = [
  { label: "Unassigned chats", value: 49 },
  { label: "Assigned and not replied", value: 29 },
  { label: "Assigned chats", value: 16 },
];

export const dialData: DialType[] = [
  {
    id: 1,
    label: "1",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 2,
    label: "2",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 3,
    label: "3",
    color: "gray.100",
    isButton: false,
  },

  {
    id: 4,
    label: "4",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 5,
    label: "5",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 6,
    label: "6",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 7,
    label: "7",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 8,
    label: "8",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 9,
    label: "9",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 99,
    label: "+",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 100,
    label: "0",
    color: "gray.100",
    isButton: false,
  },
  {
    id: 101,
    label: "backspace",
    color: "gray.50",
    isButton: true,
  },
];
export const otherButtons: DialType[] = [
  {
    id: 104,
    label: "",
    color: "white",
    isButton: false,
  },
  {
    id: 103,
    label: "",
    color: "transparent",
    isButton: false,
  },
];
export const mainButtons: DialType[] = [
  {
    id: "mute",
    label: "mute",
    color: "white",
    isButton: true,
  },
  {
    id: "hold",
    label: "hold",
    color: "gray.500",
    isButton: true,
  },
  {
    id: "transfer",
    label: "transfer",
    color: "gray.500",
    isButton: true,
  },
  {
    id: "survey",
    label: "survey",
    color: "gray.500",
    isButton: true,
  },
];

export const extData: ExtDataType[] = [
  {
    id: "dfhjskb",
    extension: "5476",
    password: "dsd",
  },
  {
    id: "dsfcskb",
    extension: "4355",
    password: "dsd",
  },
];
export const didData: DIDDataType[] = [
  {
    id: "HKJBH-123-434EFV",
    name: "Sales DID",
    didNumber: "254 205 674 323",
    balance: "43672",
    totalSpent: "2345676756",
    inboundCalls: "3456",
    outboundCalls: "2324",
    missedCalls: "12324",
    totalCalls: "5434",
    status: "ACTIVE",
    network: "Safaricom",
    type: "Toll Free",
  },
  {
    id: "HKJBH-124-434EFV",
    name: "CS DID Invoices",
    didNumber: "254 205 674 324",
    balance: "43672",
    totalSpent: "2345676756",
    inboundCalls: "3456",
    outboundCalls: "2324",
    missedCalls: "12324",
    totalCalls: "5434",
    status: "ACTIVE",
    network: "Safaricom",
    type: "Toll Free",
  },
  {
    id: "HKJBH-125-434EFV",
    name: "CS DID Reports",
    didNumber: "254 205 674 325",
    balance: "0",
    totalSpent: "8762",
    inboundCalls: "12",
    outboundCalls: "435",
    missedCalls: "65",
    totalCalls: "343",
    status: "ACTIVE",
    network: "Safaricom",
    type: "Toll Free",
  },
];

export const dummyPlans: DialPlanDataType[] = [
  {
    id: "1",
    name: "dummy plan",
    description: "dummy plan Lorem ipsum",
    did: ["254 205 674 324"],
    nodes: [
      {
        id: "node1",
        name: "Default IVR",
        description: "Lorem ipsum",
        recording:
          "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
        options: [
          {
            name: "Sales",
            option: "1",
            destination: "node2",
          },
          {
            name: "queue2",
            option: "1",
            destination: "node4",
          },
          {
            name: "queue3",
            option: "2",
            destination: "node5",
          },
        ],
        position: {
          x: 500,
          y: 500,
        },
        node_type: "IVR",
        next_node: "HBJCB-434123-434EFV",
        previous_node: "HBJCB-434124-434EFV",
      },
      {
        id: "node2",
        name: "Sales Team IVR",
        description: "Lorem ipsum",
        recording:
          "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
        options: [
          {
            name: "queue1",
            option: "0",
            destination: "node3",
          },
          {
            name: "queue2",
            option: "1",
            destination: "node4",
          },
          {
            name: "queue3",
            option: "2",
            destination: "node5",
          },
        ],
        position: {
          x: 1200,
          y: 500,
        },
        node_type: "IVR",
        next_node: "node3",
        previous_node: "node1",
      },
      {
        id: "node3",
        name: "string",
        description: "Lorem ipsum",
        recording:
          "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
        options: [
          {
            name: "queue",
            option: "0",
            destination: "null",
          },
        ],
        position: {
          x: 1900,
          y: 500,
        },
        node_type: "QUEUE",
        next_node: "null",
        previous_node: "node2",
      },
      {
        id: "node4",
        name: "string",
        description: "Lorem ipsum",
        recording:
          "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
        options: [
          {
            name: "queue",
            option: "0",
            destination: "null",
          },
        ],
        position: {
          x: 1900,
          y: 1000,
        },
        node_type: "QUEUE",
        next_node: "null",
        previous_node: "node2",
      },
      {
        id: "node5",
        name: "string",
        description: "Lorem ipsum",
        recording:
          "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
        options: [
          {
            name: "queue",
            option: "0",
            destination: "null",
          },
        ],
        position: {
          x: 1900,
          y: 1500,
        },
        node_type: "QUEUE",
        next_node: "null",
        previous_node: "node2",
      },
    ],
  },
  {
    id: "2",
    name: "dummy plan 2",
    description: "dummy plan Lorem ipsum",
    did: ["254 204 546 243"],
    nodes: [],
  },
  {
    id: "3",
    name: "dummy plan 3",
    description: "dummy plan Lorem ipsum",
    did: ["254 204 546 8954"],
    nodes: [],
  },
];
export const getFieldIcon = (type: string) => {
  switch (type) {
    case "text":
      return <MdOutlineTextFields />;
    case "number":
      return <TbNumbers />;
    case "date":
      return <BiCalendarStar />;
    case "checkbox":
      return <RiCheckboxLine />;
    case "radio":
      return <BiRadioCircleMarked />;
    case "textarea":
      return <BsTextareaResize />;
    case "email":
      return <MdOutlineMailOutline />;
    case "phone":
      return <HiPhone />;
    default:
      return <TbForms />;
  }
};
export const menuList = [
  {
    key: "LIVEREPORTS",
    icon: <HiOutlineDocumentReport />,
    label: strings.live_reports,
    path: "/live-reports",
  },
  {
    key: "CALLMODULE",
    icon: <MdOutlinePhone />,
    label: strings.calls,
    path: "/calls",
  },
  {
    key: "CRM",
    icon: <FaUsersCog />,
    label: strings.crm,
    path: "/crm",
  },
  {
    key: "CHATDESK",
    icon: <GiTicket />,
    label: strings.chatdesk,
    path: "/chatdesk",
  },
  {
    key: "NIA",
    icon: <BiBot />,
    label: strings.nia,
    path: "/nia",
  },
  {
    key: "SMS",
    icon: <MdSms />,
    label: strings.sms,
    path: "/sms",
  },
  {
    key: "BILLING",
    icon: <FaFileInvoiceDollar />,
    label: strings.billing,
    path: "/billing",
  },
  {
    key: "BOTBUILDER",
    icon: <BiBot />,
    label: strings.bot_builder,
    path: "/bots",
  },
];

export const RESPONSE_LOG: {
  [key: string]: { phrase: string; description: string };
} = {
  "100": {
    phrase: "Trying",
    description:
      "The server has received the request headers and is processing the request.",
  },
  "180": {
    phrase: "Ringing",
    description: "The server is alerting the user of an incoming call.",
  },
  "181": {
    phrase: "Call Is Being Forwarded",
    description: "The call is being forwarded by the server.",
  },
  "182": { phrase: "Queued", description: "The call is queued at the server." },
  "183": {
    phrase: "Session Progress",
    description:
      "The server has established a session and is providing interim information about the session.",
  },
  "200": { phrase: "OK", description: "The request has succeeded." },
  "202": {
    phrase: "Accepted",
    description:
      "The request has been accepted for processing, but the processing has not been completed.",
  },
  "300": {
    phrase: "Multiple Choices",
    description:
      "The requested resource corresponds to any one of a set of representations, each with its own specific location, and agent-driven negotiation information is being provided so that the user can select a preferred representation and redirect its request to that location.",
  },
  "301": {
    phrase: "Moved Permanently",
    description:
      "The requested resource has been assigned a new permanent URI and any future references to this resource should use one of the returned URIs.",
  },
  "302": {
    phrase: "Moved Temporarily",
    description:
      "The requested resource resides temporarily under a different URI.",
  },
  "305": {
    phrase: "Use Proxy",
    description:
      "The requested resource must be accessed through the proxy given by the Location field.",
  },
  "380": {
    phrase: "Alternative Service",
    description:
      "The call has been redirected to an alternate set of resources.",
  },
  "400": {
    phrase: "Bad Request",
    description: "The request could not be understood due to malformed syntax.",
  },
  "401": {
    phrase: "Unauthorized",
    description: "The request requires user authentication.",
  },
  "402": {
    phrase: "Payment Required",
    description: "You have insufficient airtime, please top-up and try again.",
  },
  "403": {
    phrase: "Forbidden",
    description:
      "The server understood the request, but is refusing to fulfill it.",
  },
  "404": {
    phrase: "Not Found",
    description: "The server has not found anything matching the Request-URI.",
  },
  "405": {
    phrase: "Method Not Allowed",
    description:
      "The method specified in the Request-Line is not allowed for the resource identified by the Request-URI.",
  },
  "406": {
    phrase: "Not Acceptable",
    description:
      "The resource identified by the request is only capable of generating response entities that have content characteristics not acceptable according to the accept headers sent in the request.",
  },
  "407": {
    phrase: "Proxy Authentication Required",
    description: "The client must first authenticate itself with the proxy.",
  },
  "408": {
    phrase: "Request Timeout",
    description: "The server timed out waiting for the request.",
  },
  "410": {
    phrase: "Gone",
    description:
      "The requested resource is no longer available at the server and no forwarding address is known.",
  },
  "413": {
    phrase: "Request Entity Too Large",
    description:
      "The server is refusing to process a request because the request entity is larger than the server is willing or able to process.",
  },
  "414": {
    phrase: "Request-URI Too Large",
    description:
      "The server is refusing to service the request because the Request-URI is too long.",
  },
  "415": {
    phrase: "Unsupported Media Type",
    description:
      "The server is refusing to service the request because the entity of the request is in a format not supported by the requested resource for the requested method.",
  },
  "416": {
    phrase: "Unsupported URI Scheme",
    description:
      "The URI scheme of the request is not supported by the server.",
  },
  "417": {
    phrase: "Unknown Resource-Priority",
    description:
      "The server has received a request in which the Resource-Priority header contains an unrecognized value.",
  },
  "420": {
    phrase: "Bad Extension",
    description:
      "The server did not understand a protocol extension used in the client's request.",
  },
  "421": {
    phrase: "Extension Required",
    description:
      "The server needs a specific extension not listed in the client's request.",
  },
  "422": {
    phrase: "Session Interval Too Small",
    description:
      "The requested session interval was too small for the server to engage in the session.",
  },
  "423": {
    phrase: "Interval Too Brief",
    description:
      "The requested session interval was too short or insufficient for the server to provide any meaningful information.",
  },
  "424": {
    phrase: "Bad Location Information",
    description:
      "The server has bad location information for the requesting client.",
  },
  "428": {
    phrase: "Use Identity Header",
    description:
      "The server policy requires use of the Identity header field, which is not supported by the client.",
  },
  "429": {
    phrase: "Provide Referrer Identity",
    description:
      "The server did not receive a valid Referrer-Identity from the client.",
  },
  "430": {
    phrase: "Flow Failed",
    description:
      "The flow specified in the Contact header field of the request cannot be followed due to its redirection or missing Contact header field in the response.",
  },
  "433": {
    phrase: "Anonymity Disallowed",
    description: "The request has been rejected because it was anonymous.",
  },
  "436": {
    phrase: "Bad Identity-Info",
    description:
      "The request has an Identity-Info header, and the URI scheme of that header is not a supported identity scheme.",
  },
  "437": {
    phrase: "Unsupported Certificate",
    description:
      "The server was unable to validate a certificate for the domain that signed it.",
  },
  "438": {
    phrase: "Invalid Identity Header",
    description:
      "The server has detected an invalid or missing Identity header field.",
  },
  "439": {
    phrase: "First Hop Lacks Outbound Support",
    description:
      "The first outbound proxy doesn't support the 'outbound' feature of RFC 5626.",
  },
  "440": {
    phrase: "Max-Breadth Exceeded",
    description:
      "The server cannot comply with the request because the user's Max-Breadth has been exceeded.",
  },
  "469": {
    phrase: "Bad Info Package",
    description:
      "The Info Package specified in the request's Info-Package header field was not understood by the server.",
  },
  "470": {
    phrase: "Consent Needed",
    description:
      "The source of the request did not have the permission of the recipient to make such a request.",
  },
  "480": {
    phrase: "Temporarily Unavailable",
    description:
      "The Number you are trying to reach is Temporarily Unavailable, We have notified them that you tried to call.",
  },
  "481": {
    phrase: "Call Leg/Transaction Does Not Exist",
    description:
      "The server received a request that does not match any dialog or transaction.",
  },
  "482": {
    phrase: "Loop Detected",
    description: "The server detected a loop.",
  },
  "483": {
    phrase: "Too Many Hops",
    description: "The server received a request that was too long.",
  },
  "484": {
    phrase: "Address Incomplete",
    description: "The Request-URI was incomplete.",
  },
  "485": {
    phrase: "Ambiguous",
    description: "The requested destination is ambiguous.",
  },
  "486": {
    phrase: "Busy Here",
    description:
      "The Customer you're trying to reach is Currently Busy and cannot take your call. Please call back later",
  },
  "487": {
    phrase: "Request Terminated",
    description: "The request has been terminated by a bye or cancel request.",
  },
  "488": {
    phrase: "Not Acceptable Here",
    description:
      "The callee's end system was contacted successfully but the callee is currently unavailable (for example, is busy). The response MAY indicate a better time to call in the Retry-After header field.",
  },
  "491": {
    phrase: "Request Pending",
    description: "The server has some pending request from the same dialog.",
  },
  "493": {
    phrase: "Undecipherable",
    description: "The server received a malformed request.",
  },
  "500": {
    phrase: "Server Internal Error",
    description:
      "The server encountered an unexpected condition that prevented it from fulfilling the request.",
  },
  "501": {
    phrase: "Not Implemented",
    description:
      "The server does not support the functionality required to fulfill the request.",
  },
  "502": {
    phrase: "Bad Gateway",
    description:
      "The server, while acting as a gateway or proxy, received an invalid response from the upstream server it accessed in attempting to fulfill the request.",
  },
  "503": {
    phrase: "Service Unavailable",
    description:
      "The server is currently unable to handle the request due to a temporary overload or maintenance of the server.",
  },
  "504": {
    phrase: "Server Time-out",
    description:
      "The server, while acting as a gateway or proxy, did not receive a timely response from the upstream server specified by the URI or some other auxiliary server it needed to access in attempting to complete the request.",
  },
  "505": {
    phrase: "Version Not Supported",
    description:
      "The server does not support, or refuses to support, the SIP protocol version that was used in the request message.",
  },
  "513": {
    phrase: "Message Too Large",
    description:
      "The request message length is longer than the server can process.",
  },
  "600": {
    phrase: "Busy Everywhere",
    description: "All possible destinations are busy.",
  },
  "603": {
    phrase: "Decline",
    description:
      "The destination does not wish to participate in the call or cannot do so, and additionally the user agents SHOULD provide a reason phrase.",
  },
  "604": {
    phrase: "Does Not Exist Anywhere",
    description:
      "The server has authoritative information that the user indicated in the Request-URI does not exist anywhere.",
  },
  "606": {
    phrase: "Not Acceptable",
    description:
      "The user's agent was contacted successfully but some aspects of the session description such as the requested media, bandwidth, or addressing style were not acceptable.",
  },
};

export const autoDialerStats: StatCardDataType[] = [
  {
    title: "Total Contacts",
    value: "50",
    icon: <RiUser6Line />,
  },
  {
    title: "Successful Calls",
    value: "30",
    icon: <HiPhoneOutgoing />,
  },
  {
    title: "Failed Calls",
    value: "20",
    icon: <HiPhoneMissedCall />,
  },
];
export const smsStats: StatCardDataType[] = [
  {
    title: "Total Contacts",
    value: "50",
    icon: <RiUser6Line />,
  },
  {
    title: "Delivered SMSs",
    value: "30",
    icon: <BiMessageAltCheck />,
  },
  {
    title: "Replied SMSs",
    value: "20",
    icon: <BiMessageAltDetail />,
  },
  {
    title: "Failed SMSs",
    value: "20",
    icon: <BiMessageAltMinus />,
  },
];

export const whatsappStats: StatCardDataType[] = [
  {
    title: "Total Contacts",
    value: "50",
    icon: <RiUser6Line />,
  },
  {
    title: "Delivered Texts",
    value: "30",
    icon: <BiMessageAltCheck />,
  },
  {
    title: "Replied Texts",
    value: "20",
    icon: <BiMessageAltDetail />,
  },
  {
    title: "Failed Texts",
    value: "20",
    icon: <BiMessageAltMinus />,
  },
];
export const frequencies = [
  { name: "Once", value: "once" },
  { name: "Daily", value: "daily" },
  { name: "Weekly", value: "weekly" },
  { name: "Monthly", value: "monthly" },
  { name: "Yearly", value: "yearly" },
];

export const settingsMenu: any[] = [
  {
    key: "personal",
    title: "Personal Settings",
    icon: <RiUser6Line />,
    children: [
      {
        key: "profile",
        title: "Profile",
        icon: <RiUserLine />,
        path: "my/profile",
      },
      {
        key: "password",
        title: "Password",
        icon: <BsShieldLock />,
        path: "my/password",
      },
    ],
  },
  {
    key: "bonga",
    title: "Bonga Admin",
    icon: <BrandIcon size=".7rem" icon fill="gray" />,
    children: [
      {
        key: "calls",
        title: "Calls",
        icon: <HiPhone />,
        path: "bonga/calls",
      },
      {
        key: "crm",
        title: "Crm",
        icon: <FaUsersCog />,
        path: "bonga/crm",
      },
      {
        key: "chatdesk",
        title: "Chatdesk",
        icon: <GiTicket />,
        path: "bonga/chatdesk",
      },
      // {
      // 	key: 'business rules',
      // 	title: 'Business Rules',
      // 	icon: <RiBriefcaseFill />,
      // 	path: 'bonga/business_rules'
      // }
    ],
  },
  {
    key: "Organization",
    title: "Organization",
    icon: <CgOrganisation />,
    children: [
      // {
      // 	key: "details",
      // 	title: "Details",
      // 	icon: <CgOrganisation />,
      // 	path: 'org/details'
      // },
      {
        key: "branches",
        title: "Branches",
        icon: <RiOrganizationChart />,
        path: "org/branches",
      },
      {
        key: "departments",
        title: "Departments",
        icon: <RiBuildingFill />,
        path: "org/departments",
      },
      {
        key: "users",
        title: "Users",
        icon: <FaUsers />,
        path: "org/users",
      },
      {
        key: "api_apps",
        title: "Apps",
        icon: <TbApiApp />,
        path: "org/api_apps",
      },
      {
        key: "roles",
        title: "Roles",
        icon: <FaUserShield />,
        path: "org/roles",
      },
    ],
  },
];

export const messageTypesMenu: any[] = [
  {
    key: "essentials",
    title: "Essentials",
    icon: <GoChecklist />,
    children: [
      {
        key: "text",
        title: "Send Text Message",
        description: "send text message",
        icon: <AiOutlineMessage />,
      },
    ],
  },
  {
    key: "questions",
    title: "Questions",
    icon: <AiOutlineQuestionCircle />,
    children: [
      {
        key: "button",
        title: "Buttons",
        description: "send buttons message",
        icon: <RiRadioButtonLine />,
      },
      {
        key: "yes/no",
        title: "Yes/No",
        description: "send user a yes or no question",
        icon: <GiChoice />,
      },
      {
        key: "question",
        title: "Ask Question",
        description: "ask user a question",
        icon: <AiOutlineQuestionCircle />,
      },
      {
        key: "form",
        title: "Forms",
        description: "add custom form with cutom fields",
        icon: <AiOutlineForm />,
      },
      {
        key: "rate",
        title: "Rate",
        description: "send user a rate bar",
        icon: <AiOutlineStar />,
      },
    ],
  },
  {
    key: "action",
    title: "Actions",
    icon: <TbSubtask />,
    description: "Add triggers for specific flow scenes",
    children: [
      {
        key: "handover",
        title: "Agent Handover",
        description: "handover chat to agent",
        icon: <RiUserLine />,
      },
      {
        key: "end",
        title: "End Chat",
        description: "end chat",
        icon: <AiOutlineCloseCircle />,
      },
      {
        key: "apicall",
        title: "API Request",
        description: "send an API request within flow",
        icon: <MdOutlineHttp />,
      },
    ],
  },
];

export const messageBlocks = [
  {
    name: "Message",
    description: "Send text",
    icon: <AiOutlineMessage />,
    id: "MESSAGE",
  },
  {
    name: "Buttons",
    description: "send choices",
    icon: <RiRadioButtonLine />,
    id: "BUTTON",
  },
  {
    name: "Yes/No",
    description: "",
    icon: <GiChoice />,
    id: "BOOLEAN",
  },
  {
    name: "Ask Question",
    description: "Ask anything",
    icon: <AiOutlineQuestionCircle />,
    id: "QUESTION",
  },
  {
    name: "Forms",
    description: "",
    icon: <AiOutlineForm />,
    id: "FORM",
  },
  {
    name: "Rating",
    description: "",
    icon: <AiOutlineStar />,
    id: "RATING",
  },
  {
    name: "Agent Handover",
    description: "",
    icon: <RiUserLine />,
    id: "HANDOVER",
  },
  {
    name: "End Chat",
    description: "",
    icon: <AiOutlineCloseCircle />,
    id: "END",
  },
  {
    name: "API Request",
    description: "",
    icon: <MdOutlineHttp />,
    id: "API",
  },

  {
    name: "Commerce",
    description: "connect catalogs to flow",
    icon: <AiOutlineShoppingCart />,
    id: "COMMERCE",
  },

  {
    name: "Webhook",
    description: "add webhook to flow",
    icon: <MdOutlineWebhook />,
    id: "WEBHOOK",
  },
];

export const startNode = {
  id: "1",
  position: {
    x: 0,
    y: 0,
  },
  data: {
    id: "1",
    isStartNode: true,
    isQuestionNode: false,
    name: "Start Here",
    description: "Start Here",
  },
  type: "START",
};

export const messageNode = {
  id: "2",
  position: {
    x: 0,
    y: 0,
  },
  data: {
    id: "2",
    isStartNode: false,
    name: "Message",
    description: "Message Node",
  },
  type: "MESSAGE",
};

export const buttonNode = {
  id: "3",
  position: {
    x: 0,
    y: 700,
  },
  data: {
    id: "3",
    isStartNode: false,
    name: "Buttons",
    description: "Button Node",
    options: [
      {
        name: "button 0",
        option: "0",
      },
      {
        name: "button 1",
        option: "1",
      },
      {
        name: "default",
        option: "2",
      },
    ],
  },

  type: "BUTTON",
};

export const yesNoNode = {
  id: "4",
  position: {
    x: 500,
    y: 100,
  },
  data: {
    id: "4",
    isStartNode: false,
    name: "Yes/No",
    description: "Yes or no question node",
    options: [
      {
        name: "Yes",
        option: "0",
      },
      {
        name: "No",
        option: "1",
      },
    ],
  },

  type: "BOOLEAN",
};

export const questionNode = {
  id: "0",
  position: {
    x: 0,
    y: 0,
  },
  data: {
    id: "0",
    isStartNode: false,
    isQuestionNode: true,
    name: "Question",
    description: "Question Node",
  },
  type: "QUESTION",
};

export const ratingNode = {
  id: "0",
  position: {
    x: 0,
    y: 0,
  },
  data: {
    id: "0",
    isStartNode: false,
    name: "Rating",
    description: "Rating Node",
  },
  type: "RATING",
};

export const endNode = {
  id: "0",
  position: {
    x: 800,
    y: 800,
  },
  data: {
    id: "o",
    name: "End",
    description: "End",
  },
  type: "END",
};

export const surveyDummy: Survey.AsObject = {
  id: "survey-one",
  title: "Survey one",
  description: "Lorem ipsum survey",
  organisation: "dialafrika",
  brandPoster: "",
  sectionsList: [
    {
      id: "1",
      surveyId: "survey-one",
      title: "Section 1",
      description: "Lorem Ipsum",
      index: 1,
      questionsList: [
        {
          id: "1",
          surveyId: "survey-one",
          index: 1,
          sectionId: "1",
          type: 1,
          question: "Question 1 Choice",
          description: "Sample Question for Choice type question",
          required: false,
          choice: {
            type: 0,
            optionsList: ["Option1", "Option 2", "Option 3"],
            multiple: false,
          },
        },
        {
          id: "2",
          surveyId: "survey-one",
          index: 2,
          sectionId: "1",
          type: 1,
          question: "Question 2 Choice Checkbox multiple",
          description: "Sample Question for Choice Checkboxes",
          required: false,
          choice: {
            type: 1,
            optionsList: ["Option 1", "Option 2", "Option 3"],
            multiple: true,
          },
        },
        {
          id: "3",
          surveyId: "survey-one",
          index: 3,
          sectionId: "1",
          type: 0,
          question: "Question 3",
          description: "Sample Question for just Text type",
          required: false,
          text: {
            type: 0,
            placeholder: "Enter text here",
          },
        },
        {
          id: "4",
          surveyId: "survey-one",
          index: 4,
          sectionId: "1",
          type: 0,
          question: "Question 4 Text Paragraph",
          description: "Sample Question for Paragraphs",
          required: false,
          text: {
            type: 1,
            placeholder: "Enter Paragraph here",
          },
        },
        {
          id: "5",
          surveyId: "survey-one",
          index: 5,
          sectionId: "1",
          type: 4,
          question: "Question 5 Net Promoter Score Type",
          description: "Sample Question for NPS",
          required: false,
          nps: {
            min: 0,
            max: 10,
            labelMin: "Minimum Score",
            labelMax: "Maximum Score",
          },
        },
        {
          id: "6",
          surveyId: "survey-one",
          index: 6,
          sectionId: "1",
          type: 3,
          question: "Question 6: Contact Email or tel",
          description: "Sample Question for contact info",
          required: false,
          contact: {
            placeholder: "Enter Contact info Here",
            type: 0,
          },
        },
        {
          id: "7",
          surveyId: "survey-one",
          index: 7,
          sectionId: "1",
          type: 2,
          question: "Question 7: Date",
          description: "Sample Question for dates, Date Time, or time",
          required: false,
          date: {
            type: 0,
          },
        },
        {
          id: "8",
          surveyId: "survey-one",
          index: 8,
          sectionId: "1",
          type: 5,
          question: "Question 8: ",
          description: "Sample Question for Rating",
          required: false,
          rating: {
            min: 1,
            max: 5,
            icon: 1,
          },
        },
        {
          id: "9",
          surveyId: "survey-one",
          index: 9,
          sectionId: "1",
          type: 6,
          question: "Question 9",
          description: "Sample Question for Matrix questions",
          required: false,
          matrix: {
            rowsList: ["Statement 1", "Statement 2", "Statement 3"],
            optionsList: ["Option1", "Option2", "Option3", "Option4"],
            multiple: true,
          },
        },
      ],
    },
  ],
  serialNumber: "",
  responseCount: 0,
  maxResponses: 123,
};

export const subtleColors = [
  "#8AE0CC",
  "#F26F8F",
  "#A4CFAE",
  "#F0D699",
  "#C9E0D2",
  "#F6818B",
  "#7BD687",
  "#F1E1B6",
  "#A5EEF1",
  "#FBCBA7",
  "#7FBCD6",
  "#F08775",
  "#B5D68E",
  "#F6B9E5",
  "#7EC9F2",
  "#E884C9",
  "#8BDCC9",
  "#F5E9A1",
  "#C3D8F0",
  "#F9AF68",
];
export const subtleLightColors = [
  "#D5E8D4",
  "#DCE1E3",
  "#F4D5CB",
  "#E6E4DC",
  "#D9E7E8",
  "#D8D7EB",
  "#F3E7D2",
  "#E1F1EC",
  "#E4E5E5",
  "#F4E6C5",
  "#C9E4CA",
  "#D5DCE6",
  "#F2DEDB",
  "#E5E8E8",
  "#E2E4D6",
  "#E4D9D9",
  "#F3EBDF",
  "#E7F1EA",
  "#E5E5E5",
  "#F4E3D2",
];
export const lightGrayShades = [
  "#FAFAFA",
  "#F5F5F5",
  "#F0F0F0",
  "#EAEAEA",
  "#E5E5E5",
  "#E0E0E0",
  "#DADADA",
  "#D5D5D5",
  "#D0D0D0",
  "#CACACA",
  "#C5C5C5",
  "#C0C0C0",
  "#BABABA",
  "#B5B5B5",
  "#B0B0B0",
  "#AAAAAA",
  "#A5A5A5",
  "#A0A0A0",
  "#9A9A9A",
  "#959595",
];
export const cmAvayaIncomingStats: StatCardDataType[] = [
  {
    title: "Incoming Calls",
    value: "0",
    icon: <HiPhoneIncoming />,
    source: "avaya_call_log/incoming/count/",
    filter: "avaya_call_log/incoming/count/filtered/",
  },
  {
    title: "Acknowledged Calls",
    value: "0",
    icon: <HiPhone />,
    source: "avaya_call_log/incoming/acknowledged/count/",
    filter: "avaya_call_log/records/acknowledged/count/filtered/",
  },
  {
    title: "Normal Calls",
    value: "0",
    icon: <HiPhone />,
    source: "avaya_call_log/incoming/normal/count/",
    filter: "avaya_call_log/incoming/normal/count/filtered/",
  },
];
export const cmAvayaOutgoingStats: StatCardDataType[] = [
  {
    title: "Outgoing Calls",
    value: "0",
    icon: <HiPhoneOutgoing />,
    source: "avaya_call_log/outgoing/count/",
    filter: "avaya_call_log/outgoing/count/filtered/",
  },
  {
    title: "Acknowledged Calls",
    value: "0",
    icon: <HiPhone />,
    source: "avaya_call_log/outgoing/acknowledged/count/",
    filter: "avaya_call_log/outgoing/acknowledged/count/filtered/",
  },
  {
    title: "Normal Calls",
    value: "0",
    icon: <HiPhone />,
    source: "avaya_call_log/outgoing/normal/count/",
    filter: "avaya_call_log/outgoing/normal/count/filtered/",
  },
];


export const cmAvayaAnalyticsStats: StatCardDataType[] = [
  {
    title: "Total",
    value: "0",
    icon: <MdSettingsPhone />,
    source: "avaya_call_log/all/count/",
    filter: "avaya_call_log/all/count/filtered/",
  },
  {
    title: "Incoming",
    value: "0",
    icon: <HiPhoneIncoming />,
    source: "avaya_call_log/incoming/count/",
    filter: "avaya_call_log/incoming/count/filtered/",
  },
  {
    title: "Outgoing",
    value: "0",
    icon: <HiPhoneOutgoing />,
    source: "avaya_call_log/outgoing/count/",
    filter: "avaya_call_log/outgoing/count/filtered/",
  },
  {
    title: "Missed",
    value: "0",
    icon: <MdPhoneMissed />,
    source: "avaya_call_log/missed/count/",
    filter: "avaya_call_log/missed/count/filtered/",
  },
];


export const overViewAvayaStats: StatCardDataType[] = [
  {
    title: "Total Calls",
    value: "0",
    icon: <HiPhone />,
    source: "avaya_call_log/agent/all/count/",
    filter: "avaya_call_log/agent/all/count/filtered/",
  },
  {
    title: "Total Incoming Calls",
    value: "0",
    icon: <HiPhoneIncoming />,
    source: "avaya_call_log/agent/incoming/count/",
    filter: "avaya_call_log/agent/incoming/count/filtered/",
  },
  {
    title: "Total Outgoing Calls",
    value: "0",
    icon: <HiPhoneOutgoing />,
    source: "avaya_call_log/agent/outgoing/count/",
    filter: "avaya_call_log/agent/outgoing/count/filtered/",
  },
  {
    title: "Total Missed Calls",
    value: "0",
    icon: <HiPhoneMissedCall />,
    source: "avaya_call_log/agent/missed/count/",
    filter: "avaya_call_log/agent/missed/count/filtered/",
  },
];