import { Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPenNib } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSurveyList } from "../../../gateway/gRPC/Utils/SurveyMethods";
import { Survey } from "../../../gateway/gRPC/pb/surveys_pb";
import IconButton from "../../buttons/IconButton";
import { User } from "../../../gateway/gRPC/pb/user_pb";

interface Props {
  onClose: () => void;
}
export default function SurveyResponseList({ onClose }: Props) {
  const [data, setData] = useState<Survey.AsObject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const user:User.AsObject = useSelector((state: any) => state.Profile);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getSurveyList(`${user.organisationId}`)
      .then((res) => {
        setData(res.surveysList);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleNavigate = (surveyId: string) => {
    navigate(`/apps/surveys/${surveyId}/new_response`);
    onClose();
  };
  return (
    <VStack spacing={4} justifyContent={"flex-start"} alignItems={"flex-start"}>
      {data.map((survey: Survey.AsObject) => (
        <>
          <HStack
            key={survey.id}
            spacing={4}
            justifyContent={"space-between"}
            alignItems={"center"}
            w={"100%"}
          >
            <Box>
              <Text fontWeight={"bold"}>{survey.title}</Text>
              <Text>{survey.description}</Text>
            </Box>
            <IconButton
              title={"AddResponse"}
              icon={<FaPenNib />}
              w={"30px"}
              h={"30px"}
              onClick={() => handleNavigate(survey.id)}
            />
          </HStack>
          <Divider />
        </>
      ))}
    </VStack>
  );
}
