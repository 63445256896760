import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { logoutFunc } from "./Functions";

let BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_BASE_URL
    : process.env.REACT_APP_PROD_BASE_URL;

const axiosCRMinstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosCRMinstance.interceptors.request.use(
  async (config: AxiosRequestConfig<any>) => {
    config.headers = config.headers || {};
    const accessToken: string | null = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Token ${accessToken}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export const avayaAxiosInstance = (url: string, timeout: number): AxiosInstance => {
	const config: AxiosRequestConfig = {
		baseURL: url,
		timeout: timeout
	};
	const instance = axios.create(config);
	instance.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			return config;
		},
		(error: AxiosError) => {
			return Promise.reject(error);
		}
	);
	instance.interceptors.response.use(
		(response: AxiosResponse) => {
			return response;
		},
		(error: AxiosError) => {
			return Promise.reject(error);
		}
	);
	return instance;
}

export default axiosCRMinstance;
